let tileItem = require('../../templates/stages/project-item-wrap.hbs');

export default Backbone.View.extend({
  initialize: function (options) {
    this.ModalHandler = options.ModalHandler;
    this.index = this.ModalHandler.addView(this);
  },
  className: 'cell grid-block',
  events: {
    'click': 'openModal',
  },

  render: function () {
    let data = this.model.toJSON();
    data.index = this.index;
    return this.$el.append(tileItem(data));
  },
  openModal: function () {
    this.ModalHandler.open(this);
  }
})