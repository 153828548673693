import $ from 'jquery';

export default Backbone.View.extend({
  initialize: function (options) {
    var that = this;
    this.index = [];
    this.current = null;
    this.isOpen = false;
    this.modal = null;

    $(window).on('keydown', function (event) {
      if (event.which === 37) {
        that.prev();
      }
      if (event.which === 39) {
        that.next();
      }
    })

    $(document).on('dsk.loadmore', function () {
      that.updateIndex.call(that);
    });
  },
  events: {
    'click [data-reveal-modal]': 'open',
  },
  render: function () {
    this.updateIndex();
  },
  updateIndex: function () {
    this.index = this.$('[data-reveal-modal]').each(function (i, el) {
      $(el).data('index', i);
    });

  },
  open: function (e) {
    var that = this;
    if (!this.isOpen) {
      this.current = $(e.currentTarget).data('index');
      var target = $(e.currentTarget).data('reveal-modal');
      this.modal = $('#' + target);
      this.modal.one('open.zf.reveal', function () {
        that.onOpen.call(that);
      }).one('closed.zf.reveal', function () {
        that.close.call(that);
      }).foundation('open');
      this.isOpen = true;
    }
  },
  onOpen: function () {
    var that = this;
    var $current = this.modal;
    var modalContent = $current.find('.modal-content')
    var styles = getComputedStyle(modalContent[0]);

    // If the device supports webkit-overflow-scrolling, meaning
    // we are on an ios device, then don't add fake scrollbars
    if (styles['-webkit-overflow-scrolling'] !== 'touch') {
      modalContent.overlayScrollbars({});
    }

    $('[data-close]', this.modal).one('click', this.close);
    var $s1 = $('.slider--one', $current).not('.slick-initialized');
    var $s2 = $('.slider--two', $current).not('.slick-initialized');
    var prev = this.getPrev();
    var next = this.getNext();
    $('body').addClass('prevent-scrolling');

    if (prev) {
      $('[data-nav-prev]', $current).show().off('click').one('click', function () {
        that.prev();
      }).find('.js-proj-label').text($(prev).data('title'));
    } else {
      $('[data-nav-prev]', $current).hide();
    }
    if (next) {
      $('[data-nav-next]', $current).show().off('click').one('click', function () {
        that.next();
      }).find('.js-proj-label').text($(next).data('title'));
    } else {
      $('[data-nav-next]', $current).hide();

    }

    $s1.slick(
      {
        slidesToShow: 1,
        dots: false,
        autoplay: false,
        autoplayspeed: 2000,
        fade: true,
        asNavFor: '#' + $s2.attr('id')
      }
    );

    $s2.slick(
      {
        slidesToShow: 3,
        dots: false,
        autoplay: false,
        asNavFor: '#' + $s1.attr('id'),
        focusOnSelect: true,
        arrows: true
      }
    );

  },
  close: function () {
    this.isOpen = false;
    var $current = this.modal;
    $('.slick-initialized', $current).slick('unslick');
    $('body').removeClass('prevent-scrolling');

  },
  next: function () {

    if (this.current === this.index.length - 1) {
      return;
    }
    this.current++;

    this.modal.foundation('close');
    this.open({currentTarget: this.index[this.current]});
  },
  prev: function () {
    if (this.current === 0) {
      return;
    }
    this.current--;
    this.modal.foundation('close');
    this.open({currentTarget: this.index[this.current]});
  },
  getPrev: function () {
    var copy = this.current;
    if (copy === 0) {
      return false;
    }
    copy--;
    return this.index[copy]
  },
  getNext: function () {
    var copy = this.current;
    if (copy === this.index.length - 1) {
      return;
    }
    copy++;
    return this.index[copy]
  }

});