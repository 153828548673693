import $ from 'jquery';

export default Backbone.View.extend({
  initialize: function (options) {
    this.$primary = this.$('#primary');
    this.$secondary = this.$('#secondary');
    this.$send = this.$('#filterbutton');
    this.filterData = KB.appData.dsk.filter;
    this.loadMore = options.lm;
    this.init = false;
  },
  events: {
    'change #primary': 'handleChange',
    'click #filterbutton': 'loadResults'
  },
  render: function () {
    this.handleChange();
  },
  handleChange: function () {
    var that = this;
    var pval = this.$primary.val();
    var values = this.filterData[pval] || {};
    this.$secondary.empty();
    if (_.isEmpty(values.tags)) {
      this.$secondary.attr('disabled', 'disabled');
      $('<option value="">Keine Optionen</option>').appendTo(this.$secondary)
    } else {
      this.$secondary.attr('disabled', null);
    }

    _.each(values.tags, function (t) {
      $("<option value='" + t.slug + "'>" + t.name + "</option>").appendTo(this.$secondary);
    }, this)

    this.ajax().then(function (res) {

      if (that.init === false) {
        that.init = true;
        return;
      }

      if (!res.args.tax_query) {
        that.$send.find('span').text('Alle Referenzen')
        that.$send.find('span').addClass('text-link--arrow');
        return that.$send.show();
      }

      if (res.length > 1) {
        that.$send.find('span').text(res.length + ' Ergebnisse laden')
        that.$send.find('span').addClass('text-link--arrow');
        that.$send.show();
      } else if (res.length == 1) {
        that.$send.find('span').text(res.length + ' Ergebniss laden')
        that.$send.find('span').addClass('text-link--arrow');
        that.$send.show();
      } else {
        that.$send.find('span').text('Keine Ergebnisse')
        that.$send.find('span').removeClass('text-link--arrow');
        that.$send.hide();
      }
    })

  },
  loadResults: function () {
    var that = this;
    this.loadMore.loadMore({
      l: this.$primary.val(),
      tag: this.$secondary.val(),
      offset: 0,
      reset: true
    }).then(function () {
      that.$send.hide();
    })
  },
  ajax: function () {
    var baseUrl = window.tpdata.base_url + "dsk/v1/mpf?" + Date.now();
    var that = this;
    var data = jQuery.extend({}, {l: this.$primary.val()}, {tag: this.$secondary.val()});
    return jQuery.ajax({
      url: baseUrl,
      beforeSend: function () {
        that.isLoading = true;
      },
      data: data
    })
  }

});