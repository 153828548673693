import Base from '../SelectFilterBase';

var selectBoxTpl = require('../../templates/select-box.hbs');
var selectBoxItems = require('../../templates/select-entries.hbs');

export default Base.extend({
  name: 'Themenbereich',
  initialize: function (options) {
    Base.prototype.initialize.apply(this, arguments);
    this.selected = [];
    this.tags = [];
    this.render();
    this.controller.on('filter.data.loaded', (e) => {
      this.renderItems();
    }, this)
  },
  events: {
    'change input': 'updateSelected',
    'click .fi--header': 'toggleBox',
    'click .ref-tag-item': 'handleTagClick',
  },
  reset: function () {
    this.selected = [];
    this.tags = [];
  },
  handleTagClick: function (event) {
    let $target = jQuery(event.currentTarget);
    let tags = [];
    $target.toggleClass('tag-selected');
    this.$('.tag-selected').each((i, e) => {
      let tagId = jQuery(e).data('term-id');
      let parent = jQuery(e).data('term-parent');
      if (this.selected.includes(parent.toString())) {
        tags.push(tagId);
      }

    })
    this.tags = tags;
    _.defer(() => {
      this.trigger('topics.changed', this.selected);
    })

  },
  toggleBox: function () {
    this.$('.fi--entries').slideToggle(150);
  },
  updateSelected: function () {
    let selection = [];
    this.$("input:checked").each(function () {
      selection.push(jQuery(this).val());
      jQuery(this).closest('.ref-topic-wrap').toggleClass('selected');
    });
    this.selected = selection;
    this.trigger('topics.changed', this.selected);
  },
  renderItems: function () {
    let items = this.controller.model.get('topics');
    if (this.selected.length === 0) {
      items = _.map(items, (i) => {
        i.count = i.possibleItems;
        i.prefix = '';
        return i;
      });
    } else {
      items = _.map(items, (i) => {
        i.count = (i.possibleItems === 0) ? '' : i.possibleItems;
        i.prefix = (i.possibleItems === 0) ? '' : '+';
        return i;
      });
    }
    this.$('.fi--entries').empty().append(selectBoxItems({items: items}));
  },
  render: function () {
    this.$el.empty().append(selectBoxTpl({name: this.name}));
    this.$list.append(this.$el);
  }
})