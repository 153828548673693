let baseTpl = require('../templates/filter-base.hbs');
import TopicsSelect from './boxes/TopicsSelect';
import TagsSelect from './boxes/TagsSelect';
import StateSelect from './boxes/StateSelect';
import ZipRadius from './boxes/ZipRadius';
import BookmarkManager from './bookmark/BookmarkManager';
import tippy from 'tippy.js/dist/tippy.esm'
import iziToast from "izitoast";


import Stage from './Stage';
import $ from 'jquery';

export default Backbone.View.extend({
  initialize: function () {
    this.geomode = 'state';
    this.model = new Backbone.Model({
      states: [],
      topics: []
    });
    this.geoconfig = null;

  },
  events: {
    'click [data-mode]': 'switchMode',
    'click [data-reset-filter]': 'resetFilter'
  },
  resetFilter: function () {
    this.TopicsSelect.reset();
    this.StateSelect.reset();
    this.ZipRadius.reset();
    this.trigger('map.reset');
    this.getMarkers();
  },
  activateGeoMode: function (mode) {
    if (this.geomode === mode) {
      return;
    }

    if (mode === 'zip') {
      this.ZipRadius.$('.fi--entries').slideDown();
      this.StateSelect.$('.fi--entries').slideUp();
      this.geomode = 'zip';
    }
    if (mode === 'state') {
      this.ZipRadius.$('.fi--entries').slideUp();
      this.StateSelect.$('.fi--entries').slideDown();
      this.geomode = 'state';
      this.geoconfig = null;
      this.trigger('map.reset');
    }
  },
  switchMode: function (e) {
    let $t = $(e.currentTarget);
    this.$('.icon-wrap').removeClass('active');
    $t.addClass('active');
    let data = $t.data();
    this.Stage.switchMode(data.mode);
  },
  render: function () {
    this.$el.empty();
    this.$el.append(baseTpl);
    this.setup();
  },
  setup: async function () {
    let that = this;
    let $list = this.$('[data-filter-list]');

    this.TopicsSelect = new TopicsSelect({controller: this, $wrap: $list})
    this.TagsSelect = new TagsSelect({controller: this, $wrap: $list});
    this.StateSelect = new StateSelect({controller: this, $wrap: $list});
    this.ZipRadius = new ZipRadius({controller: this, $wrap: $list});
    this.Stage = new Stage({controller: this, el: '[data-filter-stage]'});
    let fdata = await jQuery.ajax(window.tpdata.endpoints.filterdata);
    this.BookmarkManager = new BookmarkManager({controller: this, $wrap: this.$('.bk-holder')});

    this.TopicsSelect.on('topics.changed', () => {
      this.configChanged = true;
      this.trigger('filter.config.changed')
    });

    this.StateSelect.on('states.changed', () => {
      this.configChanged = true;
      this.trigger('filter.config.changed')
    });

    this.ZipRadius.on('zipcode.changed', () => {
      this.configChanged = true;
      this.trigger('filter.config.changed')
    });

    this.model.set(fdata);
    _.defer(function () {
      that.trigger('filter.data.loaded')
      that.initialized = true;
    })

    if (L.Browser.mobile !== true){
      let tips = tippy('[data-tippy-content]', {
        theme: 'dskbig'
      });
    }

  },
  getMarkers: async function (params) {
    params = params || {};
    this.$el.addClass('data-loading');
    let fdata = await jQuery.ajax(window.tpdata.endpoints.markers, {
      method: 'POST',
      data: this.buildParameters(params)
    });
    if (fdata.filterdata) {
      this.setFilterData(fdata.filterdata);
    }
    if (fdata.geoconfig) {
      this.geoconfig = fdata.geoconfig
    }
    this.trigger('filter.markers.loaded', fdata);
    this.$el.removeClass('data-loading');
    if (fdata.pagination.totalCount > 1) {
      this.$('[data-results]').text(fdata.pagination.totalCount + ' Ergebnisse')
    }
    if (fdata.pagination.totalCount === 1) {
      this.$('[data-results]').text(fdata.pagination.totalCount + ' Ergebnis')
    }
    if (fdata.pagination.totalCount === 0) {
      this.$('[data-results]').text('Keine Ergebnisse')
    }

    if (L.Browser.mobile) {
      let $target = $('.filter').offset();
      $('html, body').animate({scrollTop: $target.top-60});
    }

    return fdata;
  },
  setFilterData: function (data) {
    let topics = _.map(this.TopicsSelect.selected, (t) => {
      return parseInt(t, 10);
    });
    let states = this.StateSelect.selected;
    _.each(data.topics, (t) => {
      t.term.checked = '';
      if (_.contains(topics, t.term.term_id)) {
        t.term.checked = 'checked="checked"';
        t.selected = true;
      }
    })

    let tags = this.TopicsSelect.tags;
    _.each(data.topics, (t) => {
      let termTags = t.term.tags;
      _.each(termTags, (tag) => {
        tag.tagSelected = false;
        if (_.contains(tags, tag.term_id)) {
          tag.tagSelected = true;
        }
      })
    });

    _.each(data.states, (t) => {
      t.term.checked = '';
      if (_.contains(states, t.term.term_id)) {
        t.term.checked = 'checked="checked"';
        t.selected = true;
      }
    })
    this.model.set(data);
    this.trigger('filter.data.loaded');
  },
  buildParameters: function (params) {
    let topics = this.TopicsSelect.selected;
    let tags = this.TopicsSelect.tags;
    let states = this.StateSelect.selected;
    let zipcode = (this.ZipRadius.zipcode.length === 5) ? this.ZipRadius.zipcode : '';
    let radius = this.ZipRadius.radius;

    if (this.geomode === 'state') {
      zipcode = '';
    }

    return Object.assign({}, {
      topics: topics,
      tags: tags,
      states: states,
      zipcode: zipcode,
      radius: radius,
      offset: params.offset || 0,
      number: params.number || 500
    }, params)
  },
  addBookmark: function (item) {
    let that = this;
    this.BookmarkManager.addItem(item);
    iziToast.show({
      class: 'dskbig',
      title: item.model.get('formattedTitle'),
      titleColor: '#1f2b5b',
      message: 'wurde der Merkliste hinzugefügt',
      backgroundColor: '#ffffff',
      theme: 'light', // dark
      image: item.model.get('featured'),
      imageWidth: 50,
      balloon: false,
      close: true,
      closeOnEscape: false,
      closeOnClick: false,
      displayMode: 0, // once, replace
      position: 'bottomRight', // bottomRight, bottomLeft, topRight, topLeft, topCenter, bottomCenter, center
      targetFirst: true,
      timeout: 5000,
      rtl: false,
      animateInside: true,
      drag: true,
      transitionIn: 'fadeInUp',
      transitionOut: 'fadeOutDown',
      buttons: [
        ['<button class="bk-toast-button"><b>Merkliste öffnen</b></button>', function (instance, toast) {
          that.BookmarkManager.openDialog();
          instance.hide({transitionOut: 'fadeOut'}, toast, 'button');
        }, true]
      ],
    });
  },
  removeBookmark: function (item) {
    let that = this;
    this.BookmarkManager.removeItem(item);
    iziToast.show({
      class: 'dskbig',
      title: item.model.get('formattedTitle'),
      titleColor: '#1f2b5b',
      message: 'wurde von der Merkliste entfernt',
      backgroundColor: '#ffffff',
      theme: 'light', // dark
      image: item.model.get('featured'),
      imageWidth: 50,
      balloon: false,
      close: true,
      closeOnEscape: false,
      closeOnClick: false,
      displayMode: 0, // once, replace
      position: 'bottomRight', // bottomRight, bottomLeft, topRight, topLeft, topCenter, bottomCenter, center
      targetFirst: true,
      timeout: 5000,
      rtl: false,
      animateInside: true,
      drag: true,
      transitionIn: 'fadeInUp',
      transitionOut: 'fadeOutDown',
      buttons: [
        ['<button class="bk-toast-button"><b>Merkliste öffnen</b></button>', function (instance, toast) {
          that.BookmarkManager.openDialog();
          instance.hide({transitionOut: 'fadeOut'}, toast, 'button');
        }, true]
      ],
    });
  }
});