import $ from 'jquery';

export default Backbone.View.extend({

  initialize: function (options) {
    this.isLoading = false;
    this.$parent = this.$('[data-filter-wrap]');

    this.$tags = this.$('[data-filter-tags]');
    this.selectedTopic = '';
    this.selectedEditor = this.$('[data-editor]').data('editor') || '';
    this.selectedTag = '';
    this.selectedTags = [];
    this.$currentTag = null;
    this.cb = options.initLoadMore;
    this.loadmore = options.lm;
    this.isOpen = false;
    this.renderTagToggle();
    this.loadFromQueryString();
  },
  events: {
    'click .tag': 'handleTag',
    'change select ': 'handleTopic',
    'click .filter-reset': 'reset',
    'click .js-tag-toggle': 'toggleTags'
  },
  reset: function () {
    this.$('select').prop('selectedIndex', 0);
    this.$('.tag').removeClass('tag--one').addClass('tag--two');
    this.selectedTopic = '';
    this.setTag('');
    this.selectedTags = [];
    this.loadMore();
    if (this.loadmore) {
      this.loadmore.containerData.offset = 8;
    }
  },
  toggleTags: function (e) {
    var that = this;
    if (this.$toggleContainer.data('open')) {
      this.$toggleContainer.removeClass('tags-vis').delay(200).slideToggle(150, function () {
        that.$toggleContainer.data('open', !that.$toggleContainer.data('open'))
        that.isOpen = that.$toggleContainer.data('open');
      });
    } else {
      this.$toggleContainer.slideToggle(150, function () {
        that.$toggleContainer.addClass('tags-vis').data('open', !that.$toggleContainer.data('open'));
        that.isOpen = that.$toggleContainer.data('open');
      });
    }
    var text = $(e.currentTarget).text();
    $(e.currentTarget).text(text == "Alle Kategorien" ? "Weniger Kategorien" : "Alle Kategorien");
  },
  handleTag: function (e) {
    var that = this;
    e.preventDefault();
    var $el = $(e.currentTarget);
    if (this.$currentTag) {
      this.$currentTag.removeClass('tag--one').addClass('tag--two');
    }
    this.$currentTag = $el;
    $el.toggleClass('tag--two').toggleClass('tag--one');
    this.setTag($el.data('tag'));
    _.defer(function () {
      that.loadMore();
    })
  },
  setTag: function (tag) {
    this.selectedTag = tag;
    // if (_.indexOf(this.selectedTags, tag) === -1) {
    //   this.selectedTags.push(tag);
    // } else {
    //   this.selectedTags = _.without(this.selectedTags, tag);
    // }
    if (this.loadmore) {
      this.loadmore.setData({tag: tag, offset: 8});

    }
  },
  scrollToFirst: function () {

    // if (Foundation.MediaQuery.is('small only')) {
    //   $('html, body').animate({scrollTop: ($('[data-tag="' + this.selectedTag + '"]').offset().top - 70)}, 'fast');
    // }
  },
  renderTagToggle: function () {
    if (this.$('.tag').length < 10) {
      return;
    }
    var sliced = this.$('.tag').slice(8).detach();
    $('<div class="cta cta--four"><span class="js-tag-toggle text-link text-link--arrow">Alle Kategorien</span></div>').appendTo(this.$tags);
    this.$toggleContainer = this.$('.js-tag-more');
    this.$toggleTrigger = this.$('.js-tag-toggle');
    this.$toggleContainer.append(sliced);
    if (!this.isOpen) {
      this.$toggleContainer.slideUp().data('open', false);
      this.$toggleTrigger.text('Alle Kategorien')
    } else {
      this.$toggleContainer.data('open', true);
      this.$toggleContainer.addClass('tags-vis');
      this.$toggleTrigger.text('Weniger Kategorien')
    }
  },
  loadFromQueryString: function () {
    var tag = this.getParameterByName('l');
    if (typeof tag === 'string') {
      var $el = this.$('select').val(tag).trigger('change');
    }
  },
  getParameterByName: function (name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, " "));
  },
  handleTopic: function (e) {
    this.selectedTopic = $(e.currentTarget).val();
    this.loadmore.setData({l: $(e.currentTarget).val(), offset: 8});
    this.selectedTag = null;
    this.loadMore();
  },
  loadMore: function () {
    var that = this;
    if (this.isLoading) {
      return false;
    }
    $('.reveal-overlay', 'body').remove();
    $('.reveal', 'body').remove();
    $('body').addClass('loading');
    var req = this.ajax().then(function (res) {
      that.isLoading = false;
      $('body').removeClass('loading');
      that.$parent.fadeOut(300, function () {
        that.$parent.empty().append(res.out).fadeIn(300, function () {
          that.$parent.find('.reveal').each(function (i, el) {
            jQuery(el).detach().appendTo('body');
          })
          $(document).trigger('dsk.loadmore');
          $(document).foundation();
        });
      });
      that.$tags.empty().append(res.tags);
      _.defer(function () {
        that.renderTagToggle();
        that.$('[data-tag="' + that.selectedTag + '"]').addClass('tag--one').removeClass('tag--two');
        if (that.loadmore) {
          that.loadmore.setElement(that.$('[data-lm-container]').eq(0));
          _.defer(function () {
            that.loadmore.render();
            if (res.postsLeft === true) {
              that.loadmore.showTrigger();
            } else {
              that.loadmore.hideTrigger();
            }
            that.scrollToFirst();
          })
          if ($(window).width() < 640) {
            $('html, body').animate({scrollTop: ($('[data-tag="' + that.selectedTag + '"]').offset().top - 70)}, 'fast');
          }
        }
      });
    })
  },
  ajax: function () {
    var getUrl = window.location;
    var baseUrl = window.tpdata.base_url + "dsk/v1/mp?" + Date.now();
    var that = this;
    var data = {'l': this.selectedTopic, 'tag': this.selectedTag};
    return jQuery.ajax({
      url: baseUrl,
      beforeSend: function () {
        that.isLoading = true;
      },
      data: data
    })
  }


});