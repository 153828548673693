import Base from '../SelectFilterBase';

var selectBoxTpl = require('../../templates/select-box.hbs');
var selectBoxItems = require('../../templates/select-entries.hbs');
export default Base.extend({
  name: 'Bundesland',
  initialize: function (options) {
    Base.prototype.initialize.apply(this, arguments);
    this.selected = [];
    this.render();
    this.controller.on('filter.data.loaded', (e) => {
      this.renderItems();
    }, this)
  },
  events: {
    'change input': 'updateSelected',
    'click .fi--header': 'toggleBox'
  },
  reset: function () {
    this.selected = [];
  },
  toggleBox: function () {
    this.controller.activateGeoMode('state');
  },
  updateSelected: function () {
    let selection = [];
    this.$("input:checked").each(function () {
      selection.push(jQuery(this).val());
    });
    this.selected = selection;
    this.trigger('states.changed', this.selected);
  },
  renderItems: function () {
    let items = this.controller.model.get('states');
    if (this.selected.length === 0) {
      items = _.map(items, (i) => {
        i.count = i.possibleItems;
        i.prefix = '';
        return i;
      });
    } else {
      items = _.map(items, (i) => {
        i.count = (i.possibleItems === 0) ? '' : i.possibleItems;
        i.prefix = (i.possibleItems === 0) ? '' : '+';
        return i;
      });
    }
    items = _.map(items, (i) => {
      i.count = (i.possibleItems === 0) ? '' : i.possibleItems;
      return i;
    });
    this.$('.fi--entries').empty().append(selectBoxItems({items: items}));

  },
  render: function () {
    this.$el.empty().append(selectBoxTpl({name: this.name}));
    this.$list.append(this.$el);
    this.renderItems();
  }
});