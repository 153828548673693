const Player = require('@vimeo/player').default;
module.exports = Backbone.View.extend({

  initialize: function () {
    var that = this;
    this.$stage = this.$('.media-slider-stage');
    this.$nav = this.$('.media-slider-nav');
    jQuery(window).resize(function () {
      that.resize();
    })
    this.$currentSlide = null;
    this.$('.slide-nav-item').first().addClass('active-nav-item');
  },
  events: {
    'click .slick-slide': 'activateSlide',
    'click .slide-nav-item': 'goToSlide'
  },
  render() {
    this.$stageSlider = this.$stage.slick({
      slidesToShow: 1,
      slidesToScroll: 1,
    });

    // this.$navSlider = this.$nav.slick({
    //   vertical: true,
    //   slidesToShow: 6,
    //   asNavFor: this.$stageSlider[0],
    //   slidesToScroll: 1,
    //   focusOnSelect: true
    // })

    this.$stageSlider.on('beforeChange', function (e) {
      // var $slide = $(e.currentTarget);
      // console.log($slide);
    })

    this.resize();

  },
  goToSlide(e) {
    var that = this;
    var $target = jQuery(e.currentTarget);
    var index = $target.data('index');
    jQuery('.active-nav-item').removeClass('active-nav-item');
    $target.addClass('active-nav-item');
    let s = this.$('.media-slider-stage').slick('slickGoTo', index)
    setTimeout(function () {
      that.activateSlide({currentTarget: that.$('.slick-active')})
    }, 300);
  },
  activateSlide(e) {
    if (this.player) this.player.unload();
    this.$currentSlide = jQuery(e.currentTarget);
    var url = this.$currentSlide.find('.frame-holder').data('url');
    var img = this.$currentSlide.find('.frame-holder img');
    this.$currentSlide._image = img;
    this.$currentSlide.find('.frame-holder').html(`<iframe frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen allow="autoplay" src="${url}"></iframe> `)
    var frame = this.$currentSlide.find('iframe');
    this.player = new Player(frame);
    this.player.play();
  },
  resize() {
    let w = this.$stage.width();
    let h = w / 1.7777777778;
    this.$('.media-slider-stage .slide').height(h)
    var styles = getComputedStyle(this.$nav[0]);
    if (styles['-webkit-overflow-scrolling'] !== 'touch') {
      this.$nav.overlayScrollbars({});
    }
    if (Foundation.MediaQuery.is('medium')) {
      this.$el.height(h);
      this.$nav.height(h)
    } else {

      this.$nav.height('auto')
      this.$el.height('auto')
    }

    // this.$navSlider.slick('resize')
  }


});