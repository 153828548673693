export default Backbone.View.extend({
  initialize: function (options) {
    this.data = this.$el.data();
    this.map = L.map(this.el, {
      center: [this.data.lat, this.data.lng],
      zoom: 15,
      minZoom: 5,
      dragging: true,
      zoomControl: false
    });


    // ESRI Base-Layer
    this.Esri_WorldGrayCanvas = L.tileLayer('https://server.arcgisonline.com/ArcGIS/rest/services/Canvas/World_Light_Gray_Base/MapServer/tile/{z}/{y}/{x}', {
      attribution: '',
      maxZoom: 16
    }).addTo(this.map);
    new L.Marker([this.data.lat, this.data.lng], {
      icon: new L.Icon({
        iconSize: [25, 41],
        iconAnchor: [13, 41],
        iconUrl: '/wp-content/themes/dskbig/assets/images/marker-icon.png',
        shadowUrl: '/wp-content/themes/dskbig/assets/images/marker-shadow.png'
      })
    }).addTo(this.map);

  }
});