import $ from "jquery";
import imgLoaded from 'imagesloaded';
import tippy from 'tippy.js/dist/tippy.esm'

let modal = require('../../templates/stages/modal.hbs');
let gallery = require('../../templates/stages/gallery.hbs');
export default Backbone.View.extend({
  views: [],
  activeView: null,
  className: 'dsk-modal reveal large modal--one',
  events: {
    'click [data-close]': 'closeModal',
    'click [data-nav-next]': 'next',
    'click [data-nav-prev]': 'prev',
    'click [data-enlarge]': 'enlargeGallery',
    'click [data-close-gallery]': 'closeGallery',
    'click [data-add-bookmark]': 'handleBookmark',
    'click [data-remove-bookmark]': 'handleBookmarkRemove',
  },
  initialize: function (options) {
    this.controller = options.controller;
    this.render();

  },
  closeGallery: function () {
    this.$s3.slick('destroy');
    this.$s4.slick('destroy');
    this.$('.modal-content').removeClass('gallery-opened');
    this.$('.modal__gallery').remove();
  },
  handleBookmark: function () {
    this.controller.addBookmark(this.activeView);
    this.setContent(this.activeView)
  },
  handleBookmarkRemove: function () {
    this.controller.removeBookmark(this.activeView);
    this.setContent(this.activeView)
  },
  enlargeGallery: function () {
    this.$('.modal-content').addClass('gallery-opened').append(gallery({c: this.activeView.model.toJSON()}))
    this.$s3 = $('.slider--three', this.$el).not('.slick-initialized');
    this.$s4 = $('.slider--four', this.$el).not('.slick-initialized');
    this.$s3.slick(
      {
        slidesToShow: 1,
        dots: false,
        autoplay: true,
        autoplayspeed: 3000,
        fade: false,
        centerMode: false,
        asNavFor: '#' + this.$s4.attr('id')
      }
    );

    this.$s4.slick(
      {
        slidesToShow: 10,
        dots: false,
        autoplay: false,
        asNavFor: '#' + this.$s3.attr('id'),
        focusOnSelect: true,
        centerMode: true,
        arrows: true
      }
    );
  },
  addView: function (view) {
    this.views.push(view);
    return this.views.length - 1;
  },
  open: function (view) {
    this.renderModal(view);
  },
  renderModal: function (view) {
    this.$backdrop.show();
    this.$el.show();
    this.setContent(view);
  },
  setContent: function (view) {
    var that = this;
    this.activeView = view;
    this.$el.empty();
    let data = view.model.toJSON();
    data.isBookmarked = this.controller.BookmarkManager.isBookmarked(data.ID);
    this.$el.append(modal({
      c: data,
      next: this.getNextView().model.toJSON(),
      prev: this.getPrevView().model.toJSON()
    }));
    imgLoaded(this.$el, {}, function () {
      that.onOpen();
    })
  },
  onOpen: function () {
    let modalContent = this.$('.modal-content')
    let styles = getComputedStyle(modalContent[0]);
    tippy('[data-tippy-content]', {
      theme: 'dskbig'
    });
    // If the device supports webkit-overflow-scrolling, meaning
    // we are on an ios device, then don't add fake scrollbars
    if (styles['-webkit-overflow-scrolling'] !== 'touch') {
      modalContent.overlayScrollbars({});
    }
    this.$s1 = $('.slider--one', this.$el).not('.slick-initialized');
    this.$s2 = $('.slider--two', this.$el).not('.slick-initialized');
    $('body').addClass('prevent-scrolling');


    this.$s1.slick(
      {
        slidesToShow: 1,
        dots: false,
        autoplay: false,
        autoplayspeed: 2000,
        fade: true,
        asNavFor: '#' + this.$s2.attr('id')
      }
    );

    this.$s2.slick(
      {
        slidesToShow: 3,
        dots: false,
        autoplay: false,
        asNavFor: '#' + this.$s1.attr('id'),
        focusOnSelect: true,
        arrows: true
      }
    );

  },
  next: function () {
    let next = this.getNextView();
    this.onBeforeClose();
    this.setContent(next);
  },
  prev: function () {
    let prev = this.getPrevView();
    this.onBeforeClose();
    this.setContent(prev);
  },
  onBeforeClose: function () {
    this.$s1.slick('destroy');
    this.$s2.slick('destroy');
  },
  closeModal: function () {
    this.$el.hide();
    this.$backdrop.hide();
  },
  render: function () {
    this.$backdrop = jQuery('<div class="modal-backdrop reveal-overlay"></div>').appendTo('body').hide();
    this.$backdrop.on('click', () => {
      this.closeModal();
    })
    jQuery('body').append(this.$el);
    this.$el.hide();
  },
  getNextView: function () {
    if (!this.activeView) return false;

    let nextIndex = this.activeView.index + 1;
    if (typeof this.views[nextIndex] !== 'undefined') {
      return this.views[nextIndex];
    }
    return this.views[0];

  },
  getPrevView: function () {
    if (!this.activeView) return false;
    let prevIndex = this.activeView.index - 1;
    if (prevIndex < 0) prevIndex = this.views.length - 1;
    return this.views[prevIndex];
  }
});