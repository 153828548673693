import $ from 'jquery';

export default Backbone.View.extend({

  initialize: function (options) {
    this.render();
    this.triggerData = this.$trigger.data();
    this.containerData = this.$el.data();
  },

  setData: function (args) {
    this.containerData = jQuery.extend({}, this.containerData, args);
  },
  addEvent: function () {
    var that = this;
    this.$trigger.addClass('lm-active');
    this.$trigger.on('click', function (e) {
      e.preventDefault();
      that.loadMore({});
    })
  },
  showTrigger: function () {
    this.$trigger.show();
  },
  hideTrigger: function () {
    this.$trigger.hide();
  },
  render: function () {
    this.isLoading = false;
    this.$parent = this.$el.closest('[data-lm-wrap]');
    this.$trigger = this.$parent.find('[data-lm-trigger]');
    if (!_.isEmpty(this.triggerData) && !_.isEmpty(this.containerData)) {
      if (!this.containerData.hasmore) {
        this.$trigger.hide();
      }
      this.addEvent();
    }
  },
  setTag: function (tag) {
    this.containerData.tag = tag;
  },
  loadMore: function (moreArgs) {
    var that = this;
    var addArgs = moreArgs || {};
    if (this.isLoading) {
      return false;
    }
    $('body').addClass('loading');

    var req = this.ajax(addArgs).then(function (res) {
      $('body').removeClass('loading');
      that.isLoading = false;
      that.containerData.hasmore = res.postsLeft;
      if (moreArgs.reset) {
        that.$el.empty();
      }
      that.$el.append(res.out);
      that.containerData.offset = parseInt(res.length, 10) + that.containerData.offset;

      if (res.postsLeft === false) {
        that.hideTrigger();
      } else {
        that.showTrigger();
      }
      setTimeout(function () {
        $(document).foundation();
        that.$('.reveal').each(function (i, el) {
          jQuery(el).detach().appendTo('body');
          $(document).trigger('dsk.loadmore');
        })
      }, 5)
    })
    return req;

  },
  ajax: function (moreArgs) {
    var baseUrl = window.tpdata.base_url + "dsk/v1/mp?" + Date.now();
    var that = this;
    var data = jQuery.extend({}, this.containerData, this.triggerData, moreArgs);
    return jQuery.ajax({
      url: baseUrl,
      beforeSend: function () {
        that.isLoading = true;
      },
      data: data
    })
  }


});