import $ from 'jquery';

export default Backbone.View.extend({
  initialize: function (options) {
    this.$items = this.$('[data-submenu]');
    this.$overlays = this.$('[data-submenu-overlay]');

  },
  events: {
    'click [data-submenu]': 'open',
    'click .close-button': 'close'
  },
  open: function (event) {

    if (this.$active){
      this.$active.removeClass('active');
    }

    this.$target = $(event.currentTarget)
    var target = $(event.currentTarget).data('submenu');
    if (!this.$target.hasClass('active-menu')) {
      event.preventDefault();
    }
    this.$target.addClass('active-menu');
    this.$active = this.findOverlay(target);
    this.$active.addClass('active');
  },
  close: function (event) {
    this.$target.removeClass('active-menu');
    this.$active.removeClass('active');
  },
  findOverlay: function (target) {
    return $('[data-submenu-overlay="' + target + '"');
  },
  render: function () {
  },

});