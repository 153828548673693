var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <span class=\"ref-distance\">"
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"distancehuman") : depth0), depth0))
    + " km</span>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <img loading=\"lazy\" src=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"featured") : depth0), depth0))
    + "\"\n                     alt=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"formattedTitle") : depth0), depth0))
    + "\">\n";
},"5":function(container,depth0,helpers,partials,data) {
    return "                <div class=\"img-placeholder\"></div>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <div class=\"hover-overlay--teaser\">\n                        "
    + ((stack1 = container.lambda((depth0 != null ? lookupProperty(depth0,"formattedTeaser") : depth0), depth0)) != null ? stack1 : "")
    + "\n                    </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"hovercard-container\">\n    <div class=\"flex-container hopper\">\n        <div class=\"hover-tile\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"distance") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":12},"end":{"line":6,"column":19}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"featured") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.program(5, data, 0),"data":data,"loc":{"start":{"line":8,"column":12},"end":{"line":13,"column":19}}})) != null ? stack1 : "")
    + "            <div class=\"hover-overlay\">\n                <div class=\"hover-overlay--header align-middle\">\n                    <h4 class=\"hl hl--six\">"
    + ((stack1 = alias2((depth0 != null ? lookupProperty(depth0,"formattedTitle") : depth0), depth0)) != null ? stack1 : "")
    + "</h4>\n                </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"featured") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":18,"column":16},"end":{"line":22,"column":23}}})) != null ? stack1 : "")
    + "                <div class=\"hover-overlay--footer\">\n                    <div class=\"cta cta--three cta--white\">\n                    <span href=\""
    + container.escapeExpression(alias2((depth0 != null ? lookupProperty(depth0,"permalink") : depth0), depth0))
    + "\"\n                          class=\"text-link text-link--arrow \">mehr erfahren</span>\n                    </div>\n                </div>\n            </div>\n\n        </div>\n    </div>\n</div>";
},"useData":true});