var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                    <div class=\"slide\">\n                                        <img src=\""
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"large") : depth0), depth0))
    + "\" alt=\"\">\n                                    </div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                    <div class=\"slide\">\n                                        <img src=\""
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"large") : depth0), depth0))
    + "\"\n                                             alt=\"\">\n                                    </div>\n";
},"5":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                    gallery_"
    + alias2(alias1(((stack1 = (depths[1] != null ? lookupProperty(depths[1],"c") : depths[1])) != null ? lookupProperty(stack1,"ID") : stack1), depth0))
    + ".push({\n                                        src: '"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"uncropped") : depth0)) != null ? lookupProperty(stack1,"src") : stack1), depth0))
    + "',\n                                        w: '"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"uncropped") : depth0)) != null ? lookupProperty(stack1,"w") : stack1), depth0))
    + "',\n                                        h: '"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"uncropped") : depth0)) != null ? lookupProperty(stack1,"h") : stack1), depth0))
    + "'\n                                    });\n";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                    <ul class=\"list list--horizonal\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"c") : depth0)) != null ? lookupProperty(stack1,"oterms") : stack1),{"name":"each","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":47,"column":40},"end":{"line":49,"column":49}}})) != null ? stack1 : "")
    + "                                    </ul>\n";
},"8":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                            <li>"
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "</li>\n";
},"10":function(container,depth0,helpers,partials,data) {
    return "";
},"12":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"c") : depth0)) != null ? lookupProperty(stack1,"isBookmarked") : stack1),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.program(15, data, 0),"data":data,"loc":{"start":{"line":68,"column":44},"end":{"line":77,"column":51}}})) != null ? stack1 : "");
},"13":function(container,depth0,helpers,partials,data) {
    return "                                                <li data-tippy-content=\"von der Merliste entfernen\"\n                                                    data-remove-bookmark><span\n                                                        class=\"bookmark bookmark--selected\"><i\n                                                        class=\"icon-bookmark-selected\"></i></span></li>\n";
},"15":function(container,depth0,helpers,partials,data) {
    return "                                                <li data-tippy-content=\"zur Merkliste hinzufügen\"\n                                                    data-add-bookmark><span\n                                                        class=\"bookmark\"><i class=\"icon-bookmark\"></i></span></li>\n";
},"17":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                        <div class=\"cell large-6 xlarge-5\">\n                                            <div class=\"aside--std\">\n                                                <h6 class=\"hl hl--six\">Kennzahlen</h6>\n                                                "
    + ((stack1 = container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"c") : depth0)) != null ? lookupProperty(stack1,"kennzahlen") : stack1), depth0)) != null ? stack1 : "")
    + "\n                                            </div>\n                                        </div>\n";
},"19":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                        <div class=\"cell large-6 xlarge-7\">\n                                            <div class=\"aside--std\">\n                                                <h6 class=\"hl hl--six\">Leistungen</h6>\n                                                "
    + ((stack1 = container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"c") : depth0)) != null ? lookupProperty(stack1,"leistungen") : stack1), depth0)) != null ? stack1 : "")
    + "\n                                            </div>\n                                        </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"grid-y modal__inner\">\n    <div class=\"cell modal-header\">\n        <span class=\"modal-title\">"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"c") : depth0)) != null ? lookupProperty(stack1,"formattedTitle") : stack1), depth0))
    + "</span>\n        <button class=\"close-button\" data-close aria-label=\"Close modal\" type=\"button\">\n            <span aria-hidden=\"true\">&times;</span>\n        </button>\n    </div>\n    <div class=\"cell auto\">\n        <div class=\"modal-content\">\n            <div class=\"grid-container full overflow-hide\">\n                <div class=\"grid-x grid-margin-x\">\n                    <div class=\"cell modal__slider-cell\">\n                        <div class=\"modal__slider\">\n                            <div id=\"ref-"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"c") : depth0)) != null ? lookupProperty(stack1,"ID") : stack1), depth0))
    + "-"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"c") : depth0)) != null ? lookupProperty(stack1,"index") : stack1), depth0))
    + "-slider\" class=\"slick-slider slider--one\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"c") : depth0)) != null ? lookupProperty(stack1,"images") : stack1),{"name":"each","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":15,"column":32},"end":{"line":19,"column":41}}})) != null ? stack1 : "")
    + "                            </div>\n                            <div id=\"ref-"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"c") : depth0)) != null ? lookupProperty(stack1,"ID") : stack1), depth0))
    + "-"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"c") : depth0)) != null ? lookupProperty(stack1,"index") : stack1), depth0))
    + "-nav\"\n                                 class=\"slick-slider slider--two slider--nav\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"c") : depth0)) != null ? lookupProperty(stack1,"images") : stack1),{"name":"each","hash":{},"fn":container.program(3, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":23,"column":32},"end":{"line":28,"column":41}}})) != null ? stack1 : "")
    + "                            </div>\n                            <script>\n                                var gallery_"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"c") : depth0)) != null ? lookupProperty(stack1,"ID") : stack1), depth0))
    + " = [];\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"c") : depth0)) != null ? lookupProperty(stack1,"images") : stack1),{"name":"each","hash":{},"fn":container.program(5, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":32,"column":36},"end":{"line":38,"column":45}}})) != null ? stack1 : "")
    + "\n                            </script>\n                            <span class=\"ref-enlarge\" data-gallery=\"gallery_"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"c") : depth0)) != null ? lookupProperty(stack1,"ID") : stack1), depth0))
    + "\"><i\n                                    class=\"icon-magnifying-glass\"></i> vergrößern</span>\n                            <div class=\"project-topics aside--std\">\n                                <h6 class=\"hl hl--six hl--meta\">Themen</h6>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"c") : depth0)) != null ? lookupProperty(stack1,"oterms") : stack1),{"name":"if","hash":{},"fn":container.program(7, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":45,"column":32},"end":{"line":51,"column":39}}})) != null ? stack1 : "")
    + "\n                            </div>\n                        </div>\n                    </div>\n                    <div class=\"cell auto\">\n                        <div class=\"modal-text pad-container\">\n                            <div class=\"scroll-container\">\n                                <div class=\"modal-article-title\">\n                                    <ul class=\"ref-actions\">\n                                        <li data-tippy-content=\"PDF herunterladen\"><a class=\"icon-pdf\"\n                                                                                      target=\"_blank\"\n                                                                                      href=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"c") : depth0)) != null ? lookupProperty(stack1,"pdflink") : stack1), depth0))
    + "\"><i\n                                                class=\"icon-pdf\"></i></a></li>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"c") : depth0)) != null ? lookupProperty(stack1,"inline") : stack1),{"name":"if","hash":{},"fn":container.program(10, data, 0, blockParams, depths),"inverse":container.program(12, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":65,"column":40},"end":{"line":78,"column":47}}})) != null ? stack1 : "")
    + "\n                                    </ul>\n                                    <h2 class=\"hl hl--two\">"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"c") : depth0)) != null ? lookupProperty(stack1,"formattedTitle") : stack1), depth0))
    + "</h2>\n                                    <span class=\"ref-subtitle\">"
    + ((stack1 = alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"c") : depth0)) != null ? lookupProperty(stack1,"subtitle") : stack1), depth0)) != null ? stack1 : "")
    + "</span>\n                                </div>\n                                "
    + ((stack1 = alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"c") : depth0)) != null ? lookupProperty(stack1,"info") : stack1), depth0)) != null ? stack1 : "")
    + "\n                                <div class=\"grid-x grid-margin-x\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"c") : depth0)) != null ? lookupProperty(stack1,"kennzahlen") : stack1),{"name":"if","hash":{},"fn":container.program(17, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":86,"column":36},"end":{"line":93,"column":43}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"c") : depth0)) != null ? lookupProperty(stack1,"leistungen") : stack1),{"name":"if","hash":{},"fn":container.program(19, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":94,"column":36},"end":{"line":101,"column":43}}})) != null ? stack1 : "")
    + "                                </div>\n                            </div>\n                        </div>\n                    </div>\n                </div>\n            </div>\n        </div>\n    </div>\n    <div class=\"cell modal-footer\">\n        <div class=\"project--nav\" data-nav>\n            <div class=\"grid-x\">\n                <div class=\"cell small-6\">\n                    <div class=\"modal-nav modal-nav--left\" data-nav-prev><span\n                            class=\"modal-nav__label js-proj-label\">"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"prev") : depth0)) != null ? lookupProperty(stack1,"formattedTitle") : stack1), depth0))
    + "</span></div>\n                </div>\n\n                <div class=\"cell small-6 text-right\">\n                    <div class=\"modal-nav modal-nav--right\" data-nav-next><span\n                            class=\"modal-nav__label js-proj-label\">"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"next") : depth0)) != null ? lookupProperty(stack1,"formattedTitle") : stack1), depth0))
    + "</span></div>\n                </div>\n            </div>\n        </div>\n    </div>\n</div>\n\n";
},"useData":true,"useDepths":true});