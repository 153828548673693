let paginationTpl = require('../../templates/stages/pagination.hbs');

export default Backbone.View.extend({
  initialize: function (options) {
    this.controller = options.controller;
    this.stage = options.stage;
    this.pagination = {};
  },
  setPagination: function (pagination) {
    this.pagination = pagination;
    this.render();
  },
  render: function () {
    let total = this.pagination.totalCount+1;
    let that = this;
    this.$el.pagination({
      classPrefix: 'pagination',
      pageSize: 9,
      autoHideNext:true,
      autoHidePrevious: true,
      pageNumber: this.pagination.currentPage+1,
      dataSource: function (done) {
        var result = [];
        for (var i = 1; i < total; i++) {
          result.push(i);
        }
        done(result);
      },
      beforePageOnClick: function(e, args){
        e.preventDefault();
        that.stage.params.offset = (9*args)-9;
        that.stage.loadEntries();
      },
      callback: function (data, pagination) {
        // template method of yourself
        var html = function (data) {
          var html = '<div>';
          $.each(data, function (index, item) {
            html += '<span>' + item + '</span>';
          });
          html += '</ul>';
          return html;
        };
      }
    })


    // this.$el.empty().append(paginationTpl(this.pagination))
  }
});